import Vue from 'vue';
import App from './App.vue';
import router from './router';  // Router importieren
import Vuetify from 'vuetify';

Vue.config.productionTip = false;
Vue.use(Vuetify);

new Vue({
  vuetify: new Vuetify(),  // Initialisiere Vuetify
  router,
  render: h => h(App),
}).$mount('#app');