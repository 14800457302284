<template>
    <v-container>
      <v-row justify="center" align="center">
        <v-col cols="12" md="12">
          <v-row justify="center">
            <v-col
              v-for="(word, index) in visibleWords"
              :key="index"
              cols="12"
              md="2"
              class="d-flex justify-center"
            >
              <v-card
                :class="{'current-word': index === 0}"
                class="pa-1"
                outlined
                max-width="150"
              >
                <v-card-item class="text-center">
                  <v-card-text class="text-h5">{{ word }}</v-card-text>
                </v-card-item>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
  
      <v-row justify="center" align="center" class="mt-5">
        <v-col cols="12" md="4">
          <v-text-field
            class="mx-auto"
            label="Eingabe"
            variant="outlined"
            v-model="currentInput"
            @input="checkInput"
            @keydown.space.prevent="nextWord"
            outlined
            dense
          />
        </v-col>
      </v-row>
  
      <!-- Stats Dialog -->
      <v-dialog v-model="isDialogOpen" max-width="500">
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            Rundenergebnis
          </v-card-title>
          <v-card-text>
            <div class="stats">
              <p>Wörter pro Minute: {{ finalWpm }}</p>
              <p>Wörter pro Sekunde: {{ finalWps }}</p>
              <p>Fehlerhafte Wörter: {{ incorrectWords }}</p>
              <p>Fehlerhafte Buchstaben: {{ incorrectLetters }}</p>
            </div>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click="isDialogOpen = false"
            >
              Schließen
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </template>
  
  <script>
  import words from "../assets/typing-test/Words.js";
  
  export default {
    data() {
      return {
        words: [],
        currentInput: "",
        currentWordIndex: 0,
        startTime: null,
        incorrectWords: 0,
        incorrectLetters: 0,
        correctWords: 0,
        isDialogOpen: false,
        finalWpm: 0,
        finalWps: 0,
      };
    },
  
    computed: {
      visibleWords() {
        return this.words.slice(this.currentWordIndex, this.currentWordIndex + 5);
      },
      wpm() {
        if (!this.startTime) return 0;
        const elapsedMinutes = (Date.now() - this.startTime) / 60000;
        return elapsedMinutes > 0 ? Math.round(this.correctWords / elapsedMinutes) : 0;
      },
      wps() {
        if (!this.startTime) return 0;
        const elapsedSeconds = (Date.now() - this.startTime) / 1000;
        return elapsedSeconds > 0 ? (this.correctWords / elapsedSeconds).toFixed(2) : 0;
      },
    },
    methods: {
      checkInput() {
        const currentWord = this.visibleWords[0];
        if (this.currentInput !== currentWord.slice(0, this.currentInput.length)) {
          this.incorrectLetters++;
        }
        if (!this.startTime) {
          this.startTime = Date.now();
        }
      },
      nextWord() {
        const currentWord = this.visibleWords[0];
        if (this.currentInput.trim() === currentWord) {
          this.correctWords++;
        } else {
          this.incorrectWords++;
        }
        this.currentWordIndex++;
        this.currentInput = "";
        if (this.currentWordIndex === this.words.length) {
          this.saveFinalStats();
          this.isDialogOpen = true;
        }
      },
      saveFinalStats() {
        this.finalWpm = this.wpm;
        this.finalWps = this.wps;
      },
      resetTest() {
        this.currentWordIndex = 0;
        this.currentInput = "";
        this.startTime = null;
        this.incorrectWords = 0;
        this.incorrectLetters = 0;
        this.correctWords = 0;
      },
      shuffleArray(array) {
        for (let i = array.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1));
          [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
      },
    },
    mounted() {
      this.words = this.shuffleArray([...words]);
    },
    watch: {
      isDialogOpen(newVal) {
        if (!newVal) {
          this.resetTest();
        }
      },
    },
  };
  </script>
  
  <style scoped>
  .v-text-field {
    width: 100%;
  }
  
  .v-card {
    width: 100%;
  }
  
  .stats p {
    margin: 5px 0;
  }
  </style>
  