<template>
    <v-container>
      <h1>Welcome on my games and tools website!</h1>
      <p></p>
      <p>I run different client-sided tools and games on this page.</p>
      <p>Have a look at the navigation drawer on the left.</p>
    </v-container>
  </template>
  
  <script>
  export default {
    name: "HomePage"
  };
  </script>
  
  <style scoped>
  /* Styles für Home.vue */
  </style>
  