import { render, staticRenderFns } from "./FlaggenQuiz.vue?vue&type=template&id=5b24dc46&scoped=true"
import script from "./FlaggenQuiz.vue?vue&type=script&lang=js"
export * from "./FlaggenQuiz.vue?vue&type=script&lang=js"
import style0 from "./FlaggenQuiz.vue?vue&type=style&index=0&id=5b24dc46&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b24dc46",
  null
  
)

export default component.exports