<template>
  <v-app>
    <!-- Navigation Drawer -->
    <NavigationDrawer />

    <!-- Hauptinhalt, der sich an die Breite der Navigation anpasst -->
    <v-main>
      <v-container>
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import NavigationDrawer from './components/NavigationDrawer.vue';

export default {
  components: {
    NavigationDrawer
  }
};
</script>

<style scoped>
/* Hier stellst du sicher, dass der Inhalt nicht hinter der Navbar liegt */
.v-main {
  margin-left: 300px; /* Passe diese Breite an deine Navigation an */
}
</style>
