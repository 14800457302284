const words = [
    "warum",
    "muss",
    "ich",
    "eigentlich",
    "immer",
    "alles",
    "selber",
    "machen",
    "ich",
    "habe",
    "doch",
    "gar",
    "keine",
    "lust",
    "darauf"
];

export default words;