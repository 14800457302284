<template>
  <v-container>
    <v-row justify="center" class="mb-5">
      <v-col cols="12" md="6">
        <v-card>
          <v-card-title class="text-h5 text-center">
            Guess the flag
          </v-card-title>
          <v-card-media class="d-flex justify-center">
            <v-img :src="currentFlag.image" width="500" height="334" />
          </v-card-media>

          <v-card-text>
            <v-text-field
              v-model="userGuess"
              label="Name the country!"
              @keydown.enter="checkGuess"
              :disabled="isAnswerShown"
            />
          </v-card-text>

          <v-card-actions>
            <v-btn @click="checkGuess" color="primary" :disabled="isAnswerShown">Check</v-btn>
            <v-btn @click="showAnswer" color="red" :disabled="isAnswerShown">I DONT KNOW</v-btn>
          </v-card-actions>

          <v-divider></v-divider>

          <v-card-text>
            <div v-if="message" class="text-center">
              <p>{{ message }}</p>
            </div>
            <p v-if="isAnswerShown">The right answer is: {{ currentFlag.name }}</p>
            <p>Score: {{ score }}</p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-btn @click="nextFlag" color="secondary" :disabled="!isAnswerShown">Next flag</v-btn>
    </v-row>
  </v-container>
</template>

<script>
import countries from '@/assets/flaggenquiz/countries.json';

export default {
  data() {
    return {
      flags: [],
      currentFlagIndex: 0,
      userGuess: '',
      score: 0,
      message: '',
      isAnswerShown: false, // Flag to indicate whether the answer is shown
    };
  },
  computed: {
    currentFlag() {
      return this.flags[this.currentFlagIndex];
    },
  },
  created() {
    this.loadFlags();
  },
  methods: {
    loadFlags() {
      // Wandelt das countries-Objekt in ein Array von Flaggen um und mischt sie zufällig
      this.flags = Object.entries(countries).map(([code, name]) => ({
        code,
        name,
        image: require(`@/assets/flaggenquiz/flaggen/${code.toLowerCase()}.png`)
      })).sort(() => Math.random() - 0.5); // Zufällige Reihenfolge
    },
    checkGuess() {
      if (this.userGuess.trim().toLowerCase() === this.currentFlag.name.toLowerCase()) {
        this.message = "Richtig!";
        this.score++;
        this.isAnswerShown = false; // Die Antwort bleibt versteckt, wenn die Antwort richtig ist
        this.nextFlag(); // Nächste Flagge automatisch aufrufen, wenn die Antwort richtig ist
      } else {
        this.message = "Leider falsch."; // Nur die Fehlermeldung anzeigen
      }
      this.userGuess = ''; // Reset input field
    },
    showAnswer() {
      this.isAnswerShown = true; // Setze den Status für die Antwort-Anzeige
      this.message = ''; // Reset message, da die Antwort jetzt angezeigt wird
    },
    nextFlag() {
      if (this.currentFlagIndex < this.flags.length - 1) {
        this.currentFlagIndex++;
        this.message = ''; // Reset message
        this.isAnswerShown = false; // Reset answer visibility
      } else {
        this.message = 'Das Quiz ist vorbei! Dein Punktestand: ' + this.score;
        this.currentFlagIndex = 0; // Reset for a new game
        this.score = 0; // Reset score
      }
      this.userGuess = ''; // Reset input field
    },
  },
};
</script>

<style scoped>
.v-card {
  margin: 20px;
}
.text-center {
  text-align: center;
}
</style>
