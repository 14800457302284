import Vue from 'vue';
import Router from 'vue-router';
import Home from '../components/HomePage.vue';
import TypingTest from '../components/TypingTest.vue';
import FlaggenQuiz from '../components/FlaggenQuiz.vue';

Vue.use(Router);

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'HomePage',
      component: Home
    },
    {
      path: '/typing-test',
      name: 'TypingTest',
      component: TypingTest
    },
    {
        path: '/flaggenquiz',
        name: 'FlaggenQuiz',
        component: FlaggenQuiz
    }
  ]
});
