<template>
  <v-navigation-drawer app>
    <v-list dense>
      <v-list-item>
        <router-link to="/" class="v-list-item">
          <v-list-item-icon>
            <v-icon>mdi-home</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Home</v-list-item-title>
          </v-list-item-content>
        </router-link>
      </v-list-item>

      <v-list-item>
        <router-link to="/typing-test" class="v-list-item">
          <v-list-item-icon>
            <v-icon>mdi-speedometer</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Typing Speed Test</v-list-item-title>
          </v-list-item-content>
        </router-link>
      </v-list-item>

      <v-list-item>
        <router-link to="/flaggenquiz" class="v-list-item">
          <v-list-item-icon>
            <v-icon>mdi-flag</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Flag Quiz</v-list-item-title>
          </v-list-item-content>
        </router-link>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: "NavigationDrawer",
};
</script>

<style scoped>
.v-list-item {
  text-decoration: none;
  color: inherit;
}
</style>
